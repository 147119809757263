import { Slot } from "@radix-ui/react-slot"
import * as React from "react"
import { Blurhash as BlurhashPrimitive } from "react-blurhash"

import { cn } from "@/lib/utils"

import { useImageLoaded } from "./useImageLoaded"


export interface BlurhashProps extends React.PropsWithChildren {
  hash: string | null | undefined
  className?: string
  figureClassName?: string
}

export const Blurhash = ({ hash, children, className, figureClassName }: BlurhashProps) => {
  const [imgRef, loaded, onLoad] = useImageLoaded()
  return (
    <figure className={cn("relative", figureClassName)}>
      {hash && !loaded && <div className={cn("absolute inset-0 overflow-hidden", className)}><BlurhashPrimitive hash={hash} width="100%" height="100%" /></div>}
      <Slot ref={imgRef} onLoad={onLoad} className={cn(!loaded && "opacity-0")}>{children}</Slot>
    </figure >
  )
}


Blurhash.displayName = "Blurhash"

