import type { RefObject } from 'react'

import { useEffect, useRef, useState, useCallback } from 'react'

export const useImageLoaded = (): [
  RefObject<HTMLImageElement>,
  boolean,
  () => void
] => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const ref = useRef<HTMLImageElement>(null)

  const onLoad = useCallback((): void => {
    setLoaded(true)
  }, [setLoaded])

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onLoad()
    }
  }, [onLoad])

  return [ref, loaded, onLoad]
}
